import Api from './api'
import store from '../store'

const wsApi = new Api();

function callBitForex(symbol, callback) {
  fetch('https://maniacmansion.org/coinproxy/bitforex-omi')
    .then(response => response.json())
    .then(callback);
}
function callBitlaxy(symbol, callback) {
  fetch('https://maniacmansion.org/coinproxy/bilaxy/'+symbol)
    .then(response => response.json())
    .then(callback);
  
  }

function callGateio(symbol, callback) {
  fetch('https://maniacmansion.org/coinproxy/callGateio/'+symbol)
    .then(response => response.json())
    .then(callback);
  }

function callUniswap(symbol, callback) {
  fetch('https://maniacmansion.org/coinproxy/Uniswap/'+symbol)
    .then(response => response.json())
    .then(callback);
}


function callKucoin(symbol, callback) {
  fetch('https://maniacmansion.org/coinproxy/kucoin/'+symbol)
    .then(response => response.json())
    .then(callback);
}

function callSovryn(symbol, callback) {
  fetch('https://backend.sovryn.app/sov/current-price')
  // var coeff = 1000 * 60 * 11;
  // var date = new Date()
  // var current_tik =  new Date(Math.round(date.getTime() / coeff) * coeff).getTime()
  // fetch('https://backend.sovryn.app/datafeed/price/RBTC:SOV?type=candle&startTime='+current_tik)
    .then(response => response.json())
    .then(callback);
}


function bitForexIntervalFunction(symbol) {
  if (bitforexTimers[symbol]['running']) {
    return;
  }
  bitforexTimers[symbol]['running'] = true;
  var ticker = callBitForex(symbol, (ticker) => {
    var tick = {
      price: ticker.data[0].close,
      vol: ticker.data[0].currencyVol,
      percent: 0.0,
      chg: 0.0,
      high: ticker.data[0].high,
      low: ticker.data[0].low,
      open: ticker.data[0].close,
      time: ticker.data[0].time,
      symbol: symbol
    };
    bitforexTimers[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    bitforexTimers[symbol]['running'] = false;
  });
}
function bilaxyIntervalFunction(symbol) {

  if (bilaxyTimer[symbol]['running']) {
    return;
  }
  bilaxyTimer[symbol]['running'] = true;
  var ticker = callBitlaxy(symbol, (ticker) => {
    var tick = {
      price: Number(ticker[symbol].close),
      price: Number(ticker[symbol].close),
      vol: Number(ticker[symbol].currencyVol),
      percent: 0.0,
      chg: Number(ticker[symbol].price_change),  
      high: Number(ticker[symbol].high),
      low: Number(ticker[symbol].low),
      open: Number(ticker[symbol].close),
      time: ticker[symbol].time,
      symbol: symbol
    };
    bilaxyTimer[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    bilaxyTimer[symbol]['running'] = false;
  });
}
function gateioIntervalFunction(symbol) {
  if (gateioTimer[symbol]['running']) {
    return;
  }
  gateioTimer[symbol]['running'] = true;
  var ticker = callGateio(symbol, (ticker) => {
    var tick = {
      price: Number(ticker[0].last),
      vol: Number(ticker[0].base_volume),
      percent: Number(ticker[0].change_percentage),   
      chg: Number(ticker[0].high_24h) - Number(ticker[0].low_24h) ,   
      high: Number(ticker[0].high_24h),
      low: Number(ticker[0].low_24h),
      open: Number(ticker[0].last),
      time: Date.now(),
      symbol: symbol
    };
    gateioTimer[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    gateioTimer[symbol]['running'] = false;
  });
}


function uniswapIntervalFunction(symbol) {
  if (uniswapTimer[symbol]['running']) {
    return;
  }
  uniswapTimer[symbol]['running'] = true;
  var ticker = callUniswap(symbol, (ticker) => {
    var tick = {
      price: Number(ticker.price.rate.toFixed(2)),
      vol:  Number(ticker.price.volume24h),
      percent: 0,   
      chg: 0 ,   
      high:0,
      low: 0,
      open: Number(ticker.price.rate.toFixed(2)),
      time: Date.now(),
      symbol: symbol
    };
    uniswapTimer[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    uniswapTimer[symbol]['running'] = false;
  });
}
function kucoinIntervalFunction(symbol) {
  if (kucoinTimer[symbol]['running']) {
    return;
  }
  kucoinTimer[symbol]['running'] = true;
  var ticker = callKucoin(symbol, (ticker) => {
    var tick = {
      price: Number(ticker.data.last),
      vol:  Number(ticker.data.vol),
      percent: (Number(ticker.data.changeRate)*100).toFixed(2),   
      chg: Number(ticker.data.changePrice),
      high: Number(ticker.data.high) ,
      low: Number(ticker.data.low) ,
      open: Number(ticker.data.last),
      time: ticker.datatime,
      symbol: symbol
    };
    kucoinTimer[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    kucoinTimer[symbol]['running'] = false;
  });
}
function sovrynIntervalFunction(symbol) {
  if (SovrynTimer[symbol]['running']) {
    return;
  }
  SovrynTimer[symbol]['running'] = true;
  var ticker = callSovryn(symbol, (ticker) => {
    var tick = {
      price: Number(ticker.price),
      vol:  0,
      percent: 0,   
      chg: 0,
      high: 0 ,
      low: 0 ,
      open: Number(ticker.price),
      symbol: symbol
    };
    SovrynTimer[symbol]['lastTick'] = tick;
    store.commit('UPDATE_TICKER', tick);
    SovrynTimer[symbol]['running'] = false;
  });
}

var bitforexTimers = {};
function startBitforexTimer(symbol) {
  if (!(symbol in bitforexTimers)) {
    bitforexTimers[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    bitforexTimers[symbol]['timer'] = setInterval(
      bitForexIntervalFunction,
      6 * 1000, // seconds per tick
      symbol
    );
  }
}



var bilaxyTimer = {};
function startBilaxyTimer(symbol) {
  if (!(symbol in bilaxyTimer)) {
    bilaxyTimer[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    bilaxyTimer[symbol]['timer'] = setInterval(
      bilaxyIntervalFunction,
      20 * 1000, // seconds per tick
      symbol
    );
  }
}
var gateioTimer = {};
function startGateioTimer(symbol) {
  if (!(symbol in gateioTimer)) {
    gateioTimer[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    gateioTimer[symbol]['timer'] = setInterval(
      gateioIntervalFunction,
      6 * 1000, // seconds per tick
      symbol
    );
  }
}
var uniswapTimer = {};
function startUniswapTimer(symbol) {
  if (!(symbol in uniswapTimer)) {
    uniswapTimer[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    uniswapTimer[symbol]['timer'] = setInterval(
      uniswapIntervalFunction,
      6 * 1000, // seconds per tick
      symbol
    );
  }
}
var kucoinTimer = {};
function startKucoinTimer(symbol) {
  if (!(symbol in kucoinTimer)) {
    kucoinTimer[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    kucoinTimer[symbol]['timer'] = setInterval(
      kucoinIntervalFunction,
      6 * 1000, // seconds per tick
      symbol
    );
  }
}
var SovrynTimer = {};
function startSovrynTimer(symbol) {
  if (!(symbol in SovrynTimer)) {
    SovrynTimer[symbol] = {
      'timer': undefined,
      'lastTick': undefined,
      'running': false
    }
    SovrynTimer[symbol]['timer'] = setInterval(
      sovrynIntervalFunction,
      6 * 1000, // seconds per tick
      symbol
    );
  }
}


function endBitforexTimers(symbol) {
  if (symbol in bitforexTimers) {
    clearInterval(bitforexTimers[symbol]['timer']);
    delete bitforexTimers[symbol];
  }
}

const subscribeSymbol = function(symbol, market) {
  if (market==="binance") {
    wsApi.onTicker(symbol,(ticker) => {
      const tick = {
        price: parseFloat(ticker.c),
        vol: parseFloat(ticker.q).toFixed(2),
        percent: parseFloat(ticker.P).toFixed(2),
        chg: ticker.p,
        high: ticker.h,
        low: ticker.l,
        open: ticker.o,
        time:ticker.E,
        symbol: symbol
      };
      store.commit('UPDATE_TICKER', tick)
    })
  } else if (market==="bitforex") {
    startBitforexTimer(symbol);
  }else if (market==="bilaxy") {
    startBilaxyTimer(symbol);
  }else if (market==="gateio") {
    startGateioTimer(symbol);
  }else if (market==="uniswap") {
    startUniswapTimer(symbol);
  }else if (market==="kucoin") {
    startKucoinTimer(symbol);
  }else if (market==="sovryn") {
    startSovrynTimer(symbol);
  }
};
const unSubscribeSymbol = function(symbol, market) {
  if (market==="binance") {
    wsApi.closeSubscription('ticker',false, symbol)
  } else if (market==="bitforex") {
    endBitforexTimers(symbol);
  }
};

const subscribeChart = function(symbol, interval) {
  wsApi.onKline(symbol, interval, () => {})
};
const unSubscribeChart = function(symbol, interval) {
  wsApi.closeSubscription('kline',false, symbol, interval)
}
export {subscribeSymbol, unSubscribeSymbol, subscribeChart, unSubscribeChart}