<template>
    <div class="cryptocss">
       <div class="menu-barv2">
            
          <button style="float: right;" class="add-btn" @click="smallModal = true"><i class="fa fa-plus fa-lg" aria-hidden="true"></i></button>
        </div>
        <CModal
          title="New position"
          size="sm"
          :show.sync="smallModal"
        >
        <div class="">
          <label class='col-6'>Entry:</label>
          <input v-model.number="new_position.entry" type="number" placeholder="entry" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Take Profit 1:</label>
          <input v-model.number="new_position.tps.tp1" type="number" placeholder="tp1" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Take Profit 2:</label>
          <input v-model.number="new_position.tps.tp2" type="number" placeholder="tp2" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Take Profit 3:</label>
          <input v-model.number="new_position.tps.tp3" type="number" placeholder="tp3" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Stop loss :</label>
          <input v-model.number="new_position.stop" type="number" placeholder="stop" class='col-6'>
        </div>
        <hr>
        <div class="">
          <label class='col-6'>Market :</label>
          <v-select  :options="marketOptions" :searchable="false" :clearable="false" v-model="new_position.market"></v-select>
        </div>
        <div class="">
          <label class='col-6'>Simbolo :</label>
          <input v-model="new_position.symbol" placeholder="Simbolo" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Coin :</label>
          <input v-model="new_position.base" placeholder="base" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Base :</label>
          <input v-model="new_position.quote" placeholder="quote" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Nombre :</label>
          <input v-model="new_position.name" placeholder="nombre" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Call Price :</label>
          <input v-model.number="new_position.call_price" placeholder="call price" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Cantidad comprada :</label>
          <input v-model.number="new_position.qty_bought" type="number" placeholder="qty bought" class='col-6'>
        </div>
        <div class="">
          <label class='col-6'>Tipo de llamada :</label>
          <v-select  :options="dman_call_Type" :searchable="false" :clearable="false" v-model="new_position.call_type"></v-select>
        </div>
        <div class="">
          <label class='col-6'>Coinmarketcap id :</label>
          <input v-model="new_position.coinmarketcap_id" placeholder="call type bought" class='col-6'>
        </div>
        <div slot="footer" class="w-100">
          <CButton  color="info" class="ml-1 mr-1 float-right" @click="addCoinPair">
              Save
          </CButton>
        </div>
    </CModal>

        <CryptoBoard></CryptoBoard>
    </div>
</template>
<script>
  import coins from '@/assets/group.json'
  import CryptoBoard from '@/components/CryptoDashboard/CryptoBoard.vue'
  import { isEmpty } from '@/util/Utility'
  import {subscribeSymbol,} from '@/services/leacher'
  import vSelect from 'vue-select'
  import { mapState } from 'vuex'
  import '@/util/registerServiceWorker'
  import "@/assets/vendor/font-awesome/css/font-awesome.css";
  export default {
    name: 'dashboard',
    data() {
      return {
        currencyList: coins,
      smallModal: false,
        market: '',
        symbol: '',
        sourcecoin: '',
        destcoin: '',
        coinname: '',
        entry: '',
        tp1: '',
        tp2: '',
        tp3: '',
        coinmarketcap_id: '',
        new_position: {tps:{},orders:{}},
        baseCurrency: {}
      }
    },
    mounted(){
      if(this.currencies) {
        this.currencies.forEach(currency => {
          subscribeSymbol(currency.symbol,currency.market);
        });
      }
    },
    computed: {
      ...mapState(['currencies','marketOptions','dman_call_Type'])
    },
    components: {
      vSelect,
      CryptoBoard
    },
    methods: {
      addCoinPair() {
        if(!isEmpty(this.new_position.market) && !isEmpty(this.new_position.symbol) && !isEmpty(this.new_position.base)&& !isEmpty(this.new_position.quote) ){
         this.$store.commit('ADD_COIN_PAIR', this.new_position)
         this.smallModal= false
        }
      },
    }
  }
</script>


<style lang="scss">
  @import "@/assets/scss/CryptoDashboard/cryptodashboard.scss";
</style>
