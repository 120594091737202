<template>
    <div class="coin-box" @dblclick.stop="openDetails">
        <div class="row no-gutters coin-info">
            <div class="col-7">

                <div class="font-weight-bold">{{info.name}}</div>
                <div class="row no-gutters mt-1">
                    <div class="box-icon">
                        <span :style="{ backgroundImage : 'url('+ iconbase +')' }"></span>
                    </div>
                    <div class="col text-left">
                        <div><b>{{info.base}}</b>/{{info.quote}}</div>
                        <div class="coin-price" v-if="ticker.price">{{ticker.price || '' }}<span style="font-size: x-small; font-weight: 700; padding-left: 3px;">{{info.quote}}</span></div>
                    </div>
                </div>
            </div>
            <div :class="[(ticker.percent < 0)?'down':'up', 'col-5','text-right']" v-if="ticker.price">
                <div class="coin-per"><span class="indicator"></span><span>{{ ticker.percent }}%</span></div>
                <div class="coin-chg">{{parseFloat(ticker.chg).toFixed((info.quote === 'USDT') ? 3 : 8)}} </div>
                <div v-if="info.entry">
                    <span class="text-secondary">Entry:</span> <span class="text-dark">{{info.entry}}</span>
                  </div>
            </div>
            <div class="dd-container" :class="[{'show': showDropDown}]" v-click-outside="closeDropDown">
                    <span role="button" class="menu-btn" @click.stop="onDropDown">
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </span>
                <div class="dd-menu" v-if="showDropDown">
                    <span class="dd-item" @click="openDetails">Open</span>
                    <span class="dd-item" @click="removeCard">Delete</span>
                </div>
            </div>
        </div>
        <div v-if="info.entry">
          <div class="row" >
              <div class="col-12">
                <label class="text-right tp_down" :style="info.tps.tp1_style">{{info.tps.tp1}}</label>
                <label class="text-right tp_down" :style="info.tps.tp3_style">{{info.tps.tp3}}</label>
              </div>
          </div>
          <CProgress class="mt-1" :max="position_max">
              <CProgressBar :key="index"  v-for="(bar, index) in tp_bar" :color="bar.color" :value="bar.value" :show-value="bar.show_value" :striped="bar.striped" :animated="bar.animated" />
          </CProgress> 
          <div class="row">
              <div class="col-12">
                <label class="text-right tp_up" :style="info.tps.tp2_style">{{info.tps.tp2}}</label>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
  import Sparkline from './Sparkline.vue'
  import {unSubscribeSymbol} from '@/services/leacher'
  export default {
    props: ['ticker', 'info'],
    data() {
      return {
        showDropDown: false,
        // iconbase: 'https://raw.githubusercontent.com/rainner/binance-watch/master/public/images/icons/' + this.info.base.toLowerCase() + '_.png',
        //iconbase: 'https://raw.githubusercontent.com/coinwink/cryptocurrency-logos/master/coins/128x128/'+this.info.coinmarketcap_id+'.png', 
        // iconbase: 'https://maniacmansion.org/cryptocurrency-logos/coins/128x128/'+this.info.coinmarketcap_id+'.png', 
         iconbase: 'https://maniacmansion.org/RGBanticorsCache/https://s2.coinmarketcap.com/static/img/coins/128x128/'+this.info.coinmarketcap_id+'.png', 
      timer: null,
      position_max: 400,
      position_current_percent: 101,
      position_tp1_percent: 100,
      position_tp2_percent: 100,
      position_tp3_percent: 100,
      tp_bar: []
      }
    },
    methods: {
      onDropDown() {
        this.showDropDown = true;
      },

      clicked () {
        this.counter = Math.random() * this.max
      },
      removeCard() {
        this.showDropDown = false;
        unSubscribeSymbol(this.info.symbol, this.info.market);
        this.$store.commit('REMOVE_COIN_PAIR', this.info.symbol)
      },
      openDetails() {
        this.showDropDown = false;
        this.$router.push({name: 'infoview', params: { 'symbol_info': this.info }})
      },
      closeDropDown() {
        this.showDropDown = false;
      }
    },
    components: {
      Sparkline
    },
    mounted () {
      if (typeof this.info.entry == "number" ) {
        this.timer = setInterval(() => {
          this.position_max =  this.info.tps.tp3/this.info.entry*100
          this.position_current_percent = this.ticker.price/this.info.entry*100
          this.position_tp1_net_percent = (this.info.tps.tp1/this.info.entry*100)
          this.position_tp2_net_percent = (this.info.tps.tp2/this.info.entry*100) - this.position_tp1_net_percent
          this.position_tp3_net_percent = (this.info.tps.tp3/this.info.entry*100) - this.position_tp1_net_percent - this.position_tp2_net_percent

          this.info.tps.tp1_style = 'margin:0px; width:' + (this.info.tps.tp1/this.info.tps.tp3*100) + '%';
          this.info.tps.tp3_style = 'margin:0px; width:' + ((this.info.tps.tp3/this.info.tps.tp3*100) - (this.info.tps.tp1/this.info.tps.tp3*100)) + '%';
          this.info.tps.tp2_style = 'margin:0px; width:' + (this.info.tps.tp2/this.info.tps.tp3*100) + '%';

          let tp1_value = Math.min(this.position_tp1_net_percent, this.position_current_percent);
          let tp1_reman = this.position_tp1_net_percent - tp1_value;
          let tp1_color = (this.position_current_percent < 100.0) ? 'danger' : 'info' ;

          let tp2_value = Math.min(this.position_tp2_net_percent, this.position_current_percent - tp1_value);
          let tp2_reman = this.position_tp2_net_percent - tp2_value;
          let tp2_color = 'success';

          let tp3_value = Math.min(this.position_tp3_net_percent, this.position_current_percent - tp1_value - tp2_value);
          let tp3_reman = this.position_tp3_net_percent - tp3_value;
          let tp3_color = 'warning';

          let tpI_color = 'gradient-secondary';

          this.tp_bar = [
            { color: tp1_color, value: tp1_value, show_value: '' },
            { color: tpI_color, value: tp1_reman, },
            { color: tp2_color, value: tp2_value, show_value: '' },
            { color: tpI_color, value: tp2_reman, },
            { color: tp3_color, value: tp3_value, show_value: '' },
            { color: tpI_color, value: tp3_reman, },
          ]
          if ((tp1_reman == 0.0) && (tp2_reman == 0.0))    this.tp_bar[4]['animated'] = ''
          else if ((tp1_reman == 0.0))                     this.tp_bar[2]['animated'] = ''
          else if (this.position_current_percent >= 100.0) this.tp_bar[0]['animated'] = ''
        }, 1000)
      }
    },
    beforeDestroy () {
      clearInterval(this.timer)
      this.timer = null
    }
  }
</script>